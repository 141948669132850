import React, { useState } from 'react';
import styles from './form.module.scss';

export default function EmbeddedForm({ title, fullForm }) {
  // Initialize state for the email input
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthdayMonth, setBirthdayMonth] = useState('');
  const [birthdayDay, setBirthdayDay] = useState('');

  // Event handler for email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleBirthdayMonthChange = (e) => {
    setBirthdayMonth(e.target.value);
  };

  const handleBirthdayDayChange = (e) => {
    setBirthdayDay(e.target.value);
  };

  return (
    <div id="mc_embed_shell" className={styles.container}>
      <div id="mc_embed_signup" >
        <form
          action="https://plpcsanjose.us13.list-manage.com/subscribe/post?u=5b0bcba460bfbca3e93dd0fca&amp;id=d39b104e95&amp;f_id=0042c3e1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
          noValidate=""
        >
          <div id="mc_embed_signup_scroll balance" className={styles.signup}>
            {title && <div className={styles.title}>{title}</div>}
            <span className={styles.subtitle}>Join our mailing list for exclusive deals</span>
            <div className={fullForm ? styles.fullInputContainer : styles.inputContainer}>
              <div>
                <label htmlFor="mce-EMAIL" className={styles.required}>
                  Email Address
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  required="true"
                  placeholder="Email Address"
                  value={email} // Bind input value to the 'email' state
                  onChange={handleEmailChange} // Attach the change handler
                />
                {fullForm &&
                  <>
                  <span>Optional:</span>
                    <label htmlFor="mce-FNAME">First Name</label>
                    <input
                      type="text"
                      name="FNAME"
                      id="mce-FNAME"
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />

                    <label htmlFor="mce-LNAME">Last Name</label>
                    <input
                      type="text"
                      name="LNAME"
                      id="mce-LNAME"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={handleLastNameChange}
                    />

                    <label htmlFor="mce-PHONE">Phone Number</label>
                    <input
                      type="text"
                      name="PHONE"
                      id="mce-PHONE"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />

                    <label htmlFor="mce-MMERGE8-month">Birthday</label>
                    <div className={styles.dates}>
                        <input
                          type="text"
                          pattern="[0-9]*"
                          placeholder="MM"
                          size="2"
                          maxLength="2"
                          name="MMERGE8[month]"
                          id="mce-MMERGE8-month"
                          value={birthdayMonth}
                          onChange={handleBirthdayMonthChange}
                        />
                        /
                        <input
                          type="text"
                          pattern="[0-9]*"
                          placeholder="DD"
                          size="2"
                          maxLength="2"
                          name="MMERGE8[day]"
                          id="mce-MMERGE8-day"
                          value={birthdayDay}
                          onChange={handleBirthdayDayChange}
                        />
                    </div>
                  </>}
              </div>
              <div className="clear">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" data-type="primary" value="Subscribe" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}